<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">Weekly Courier Billing report</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>

            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
                  name="birthdate" @on-close="dateChanged"></flat-pickr>
              </div>
            </td>

            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Carrier
                  <span></span>
                </label>
                <select v-model="post_data.delivery_provider" @change="handleCarrierChange">
                  <option v-for="(carrier, i) in carriers" :key="'carriers' + i" :value="carrier.id">{{ carrier.name }}
                  </option>
                </select>
              </div>
            </td>

          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">{{ search_btn.value }}</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(bookings).length > 0">
        <header>
          <!-- <h3>Registry Dispatch System Monthly Summary</h3> -->
          <div>
            <h3>Total Bookings: {{ Object.keys(allBookings).length }} </h3>
            <p>Selected Bookings For Export: {{ Object.keys(selectedBookingIds).length }} </p>
          </div>

          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <tr>
              <td><input type="checkbox" v-model="selectAll" @change="toggleAllCheckboxes" /></td>
              <td><b>Invoice date</b></td>
              <td><b>Payee number</b></td>
              <td><b>Currency</b></td>
              <td><b>Voucher total</b></td>
              <td><b>Invoice Number</b></td>
              <td v-if="post_data.courier_code != 'STC'"><b>Upload Name</b></td>
              <td><b>Matter Number</b></td>
              <td><b>Disbursement Type (Cost code)</b></td>
              <td><b>Date Booked</b></td>
              <td><b>Narration</b></td>
              <td v-if="post_data.courier_code === 'DHL'"><b>Requester</b></td>
              <td><b>General ledger number</b></td>
              <td><b>GST</b></td>
              <td><b>GST code</b></td>
              <td><b>Value Except GST</b></td>
            </tr>

            <tbody>
              <template v-for="(provider, secIndex) in bookings">
                <!-- Section Topic Row -->
                <tr>
                  <td :colspan="3">{{ provider.provider.name }}</td>
                </tr>
                <!-- Data Rows -->
                <tr v-for="(booking, rowIndex) in provider.provider_bookings">
                  <td>
                    <input type="checkbox" :value="booking.booking_id" v-model="selectedBookingIds" />
                  </td>
                  <!-- Statement date -->
                  <td>{{ getInvoiceDate(booking.request_date) }}</td>
                  <!-- Payee number -->
                  <td>{{ getPayeeNumber(post_data.courier_code) }}</td>
                  <!-- Currency -->
                  <td>{{ 'AUD' }}</td>
                  <!-- Voucher total -->
                  <td>{{ voucherTotal.toFixed(2) }}</td>
                  <!-- Invoice Number -->
                  <td>{{ '' }}</td>
                  <!-- Upload Name -->
                  <td v-if="post_data.courier_code != 'STC'">{{ getUploadName(post_data.courier_code,
                    booking.branch.city)
                    }}</td>
                  <!-- Matter Number -->
                  <td>{{ getMatterNumber(booking) }}</td>
                  <!-- Disb type -->
                  <td>{{ 'COU' }}</td>
                  <!-- Date Booked -->
                  <td> {{ booking.request_date | formatDate }}</td>
                  <!-- Narration -->
                  <td>{{ getNarration(booking) }}</td>
                  <!-- Requester -->
                  <td v-if="post_data.courier_code === 'DHL'">{{ booking.pickup_name }}</td>
                  <!-- GL Code -->
                  <td>{{ getGLCode(booking) }}</td>
                  <!-- GST -->
                  <td>{{ getGstValue(booking.courier_rate, post_data.courier_code) }}</td>
                  <!-- GST code -->
                  <td>{{ getGstCode(post_data.courier_code) }}</td>
                  <!-- Value Except GST -->
                  <td v-if="post_data.courier_code != 'DHL'">{{ getPriceWithoutGST(booking.courier_rate) }}</td>
                  <td v-else>{{ booking.courier_rate }}</td>
                </tr>
              </template>
            </tbody>


          </table>

        </div>
      </div>
      <h4 v-if="Object.keys(bookings).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsMailroom",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      allBookings: '',
      goods: [],
      services: [],
      couriers: [],
      bookings: [],
      selectedBookingIds: [],
      selectAll: false,
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        delivery_provider: "",
        courier_code: "",
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      show_data: false,
      search_btn: {
        value: 'Search'
      }
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Reports", "Reconciled Courier Report"]);
    this.api_url = process.env.VUE_APP_API_URL;
    this.fetchCarrier();
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMMDD')
    }
  },
  methods: {
    toggleAllCheckboxes() {
      if (this.selectAll) {
        // Select all checkboxes
        this.selectedBookingIds = this.bookings
          .map(booking => booking.provider_bookings.map(booking => booking.booking_id))
          .flat();

      } else {
        // Deselect all checkboxes
        this.selectedBookingIds = [];
      }
    },
    dateChanged(daterange) {
      this.resetFields();
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    handleCarrierChange() {
      this.resetFields();
      const selectedCarrier = this.carriers.find(carrier => carrier.id === this.post_data.delivery_provider);
      this.post_data.courier_code = selectedCarrier ? selectedCarrier.carrier_code : '';
    },
    async fetchCarrier() {
      try {
        const response = await this.axios.get("/api/provider/");
        this.carriers = response.data.providers.filter(provider => provider.carrier_code !== 'STARTRACK-TV');
      } catch (error) {
        this.toast.error();
      }
    },
    resetFields() {
      this.show_data = false;
      this.bookings = [];
      this.selectedBookingIds = [];
    },
    async fetchReport() {
      // this.resetFields();
      this.search_btn.value = 'Searching...';

      await this.axios
        .post("/api/reports/get-courier-invoice-report", this.post_data)
        .then(response => {
          this.show_data = true;
          console.log(response);
          this.bookings = response.data.data.bookings;
          console.log(this.bookings);

          this.allBookings = this.bookings
          .map(booking => booking.provider_bookings.map(booking => booking.booking_id)) // Extract booking_id arrays
          .flat();

          this.voucherTotal = response.data.data.total_courier_rate;
        })
        .catch(error => {
          console.log(e);
        });

      this.search_btn.value = 'Search';
      this.selectedBookingIds = [];
    },
    downloadReport() {
      if (Object.keys(this.selectedBookingIds).length == 0) {
        this.toast.error("Please select the bookings to export!");
        return;
      }
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
        "/api/reports/get-courier-invoice-report/download?token=" +
        localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });

      // Append selected booking IDs
      input = document.createElement("input");
      input.type = "text";
      input.name = "selectedBookingIds";
      input.value = this.selectedBookingIds.join(","); // Join IDs into a comma-separated string
      form.appendChild(input);

      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    },
    getInvoiceDate(requestDate) {
      const date = new Date(requestDate); // Convert the input date to a Date object
      date.setMonth(date.getMonth() + 1); // Move to the next month
      date.setDate(1); // Set the day to the 1st

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed in JavaScript
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}${month}${day}`; // Return in YYYYMMDD format
    },
    getPayeeNumber(courier, branch) {
      const payeeNumbers = {
        'KWM NSW': {
          'STC': '10018101-010',
          'DHL': '10000617-001',
          'TOLLPRIORITY': '10020921-001'
        },
        'KWM VIC': {
          'STC': '10018101-010',
          'DHL': '10041222-001',
          'TOLLPRIORITY': '10017642-002'
        }
      };

     if (payeeNumbers[branch]) {
        if (payeeNumbers[branch][courier]) {
          return payeeNumbers[branch][courier];
        }
      }

      return '';
    },
    getUploadName(courier, city) {
      if (courier == 'DHL') {
        return 'DHL' + ' - ' + city + ' - ' + 'we';
      } else if (courier == 'TOLLPRIORITY') {
        return 'Toll Priority' + ' - ' + city + ' - ' + 'we';
      } else {
        return '';
      }
    },
    getMatterNumber(booking) {
      if (booking.custom_code == 'Matter Number') {
        return booking.custom_code_value
      } else {
        return '';
      }
    },
    getNarration(booking) {
      return 'Courier' + ' - ' + booking.request_date + ' - ' + booking.delivery_name + ' - Ref ' + booking.airway_bill + ' - ' + booking.booking_prefix + booking.booking_id
    },
    getGLCode(booking) {
      if (booking.custom_code == 'Gl Code') {
        if (!booking.custom_code_value) {
          return '';
        }
        return `21100-000000-${booking.custom_code_value}`;
      } else if (booking.custom_code != 'Matter Number') {
        return booking.custom_code_value;
      }
    },
    getGstCode(courier) {
      if (courier == 'DHL') {
        return 'AUEXEMPT';
      } else {
        return 'AUGSTINPUT';
      }
    },
    getGstValue(courier_rate, courier) {
      if (courier == 'DHL') {
        return 0;
      }
      return (courier_rate / 11).toFixed(2);
    },
    getPriceWithoutGST(courier_rate) {
      const gstValue = courier_rate / 11;
      return (courier_rate - gstValue).toFixed(2);
    }
  },
  computed: {
    // voucherTotal() {
    //   return this.bookings.reduce((total, booking) => {
    //     return total + parseFloat(booking.courier_rate || 0);
    //   }, 0).toFixed(2);
    // }
  },
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid rgb(109, 69, 69);
}
</style>
<style scoped>
.scoped_cell_width_adj td {
  width: 16.6%;
}

.link_bt {
  margin-left: 10px;
}

.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}

.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}

.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}

.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}

.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}

.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}

.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }

  .reports_to_print {
    display: block;
  }

  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }

  .despatch_summary_wrapper header .right_part {
    display: none;
  }

  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }

  .despatch_summary_table_container h2 {
    font-size: 16px;
  }

  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }

  @page {
    size: landscape;
  }
}
</style>